.lastWork{
    background-color: rgba(255, 185, 0, 0.10);
    margin-bottom: 5rem;
    padding: 3rem 0rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.lastWorkcarousel{
    height: 398px;
    width: 350px !important;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.fourthHeader{
    font-family: Hanimation Arabic;
    font-size: 24px;
    font-weight: 500;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: right;
}
.carouselPara span{
    font-family: Hanimation Arabic;
    font-size: 20px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: right;
    color: #828282;
}
.carouselIcon{
    color: #828282;
    margin-left: 5px;
}