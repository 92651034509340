.blogCard{
    width: 356px;
    height: 533px;
    left: 0px;
    top: 0px;
    margin-top: 3rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.blogDate{
    font-family: Hanimation Arabic;
    font-size: 16px;
    font-weight: 400;
    line-height: 32.8px;
    letter-spacing: 0em;
    text-align: right;
    color: #828282;
}
.blogCardHeader{
    font-family: Hanimation Arabic;
    font-size: 22px;
    font-weight: 500;
    line-height: 45.1px;
    letter-spacing: 0em;
    text-align: right;
    color: #000;
}
.blogCardPara{
    font-family: Hanimation Arabic;
    font-size: 16px;
    font-weight: 350;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    color: #828282;
}
.blogReadMore{
    font-family: Hanimation Arabic;
    font-size: 18px;
    font-weight: 400;
    line-height: 36.9px;
    letter-spacing: 0em;
    color: var(--mainColor);
    justify-content: end;
}
.blogReadMoreColor{
    color: var(--mainColor);
}
.blogReadMoreColor:hover{
    color: var(--mainColor);
    font-size: 16.5px;
}


.blogContent h3{
    font-family: Hanimation Arabic;
    font-size: 24px;
    font-weight: 500;
    line-height: 49.2px;
    letter-spacing: 0em;
    text-align: right;
    color: #000;
}
.blogContent p{
    width: 1168px;
    height: 287px;
    font-family: Hanimation Arabic;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 41px;
    text-align: right;
    color: #828282;
}
.blogCardMini{
    width: 376px;
    height: 533px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

@media (max-width: 700px){
    .blogDescImg{
        width: 343px;
        height: 400px;
    }
    .blogContent h3{
        font-size: 10px;
        line-height: 33px;
    }
    
    .blogContent p{
        font-size: 15px;
        line-height: 30px;
        width: fit-content;
    }
  }
  