.topNav{
   background-color: var(--mainColor);
   height: 37px;
   font-size: 12px;
   font-family: 'El Messiri', sans-serif;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: left;
   color: var(--fontColor);

}
.topNav a{
   color: var(--fontColor);
   text-decoration: none;
}
.topNavIcon{
   color: var(--fontColor);
   text-decoration: none;
}
.topNavIcon svg{
   width: 14px !important;
   height: 14px !important; 
}
.nav{
   background-color: var(--black);
   color: var(--fontColor) !important;
}
.nav_link
 {
   font-family: 'El Messiri', sans-serif;
   font-size: 22px !important;
   font-weight: 300;
   line-height: 45px;
   letter-spacing: 0em;
   text-align: center;
   margin-left: 20px;

}
.nav_link , .nav_linkImage{
   font-family: 'El Messiri', sans-serif;
   font-size: 19px;
   font-weight: 300;
   line-height: 45px;
   letter-spacing: 0em;
   text-align: center;
   margin-left: 20px;
   
}
/*active*/
.nav_link:active{
   font-weight: 500 !important;
   background-color: var(--mainColor) !important;
   border-radius: 15px !important;
   padding: 5px 15px;
 }
@media (max-width: 700px){
   .topNav{
      display: none;
   }
   .nav_linkImagePart{
      display: none;
   }
 }