.form-field {
    max-width: 500px;  /* Change this value as per your requirements */
}
*{font-family: 'Cairo', sans-serif;
}

.form-check-right {
    justify-content: flex-end;
    direction: rtl;
}
.form-check-label {
    margin-right: 50px;
}
.thank-you-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .thank-you-heading {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #333;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    line-height: 70px;

  }
  .formcontainer {
    background-image: url('./FormBG.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: "100%";
    
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .container1 {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
   background-color: #0000004b;
  }

  .mcon {
    background-image: url('./FormBG.png');
    height: 100vh;
  }


  .container1 h1 {
    color: #ffffffdc;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .container1 p {
    margin-bottom: 10px;
    color: #ffffffc0;

  }

  .highlight {
    color: #ff6600;
    font-weight: bold;
  }