.topFooter{
    background-color: var(--black);
}
.topFooterHieght{
    height: max-content;
}
.footerMeun{
    color: var(--fontColor);
    list-style:none;
    font-family: Hanimation Arabic;
    font-size: 22px;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}
.footerMeun a:hover{
    color: var(--fontColor);
}
.footer_item{
    color: var(--fontColor);
    text-decoration: none;
    height: max-content;
}
.topFooterIcon{
    background-color: var(--mainColor);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    color: var(--fontColor);
}
.footer{
    background-color: #000000;
    color: #ffffff;
    padding: 0.7rem;
}
.footer .container{
    width: 100%;
}
.rightFooter{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.topNavIcon{
    color: #ffb900;
    text-decoration: none;
}
.leftFooter ul{
    margin: auto;
}
.footerMeun li{
    width: 20%;
}
@media (max-width: 700px){
    .firstFooterItem{
        order: 1;
        width: 80%;
    }
    .secondFooterItem{
        order: 3;
        width: 100%;
    }
    .thirdFooterItem{
        order: 2;
        width: 20%;
    }
    .footerMeun li{
        width: 40%;
    }
    .rightFooter{
        font-size: 14px;
        line-height: 14px;
    }

    .leftFooter ul{
        margin: 0px;
    }

    .topFooter img{
        width: 122.73px;
        height: 118.54px;
    }
}
.nav_link,
.nav_linkImage {
    font-family: 'El Messiri', sans-serif;
    font-size: 19px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 20px;
}

/*active*/
.nav_link:active {
    font-weight: 500 !important;
    background-color: var(--mainColor) !important;
    border-radius: 15px !important;
    padding: 5px 15px;
}