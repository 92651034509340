.aboutPara{
    font-family: Hanimation Arabic;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 40px;
    text-align: right;
    color:var(--fontBrimaryColor);
}
p{
    color:var(--fontBrimaryColor);
    font-family: Hanimation Arabic;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0em;
    text-align: right;
}
.aboutLeftSection{
    padding-bottom: 20rem;
}
.aboutSecondImage{
    position: absolute;
    right: 220px;
    top: 180px;
}
.aboutThirdImage{
    position: absolute;
    left: 197px;
    top: 155px;
}
@media (max-width: 700px){
    .aboutPara{
        font-size: 18px;
        line-height: 37px;
    }
    .aboutFirstImage{
        width: 252.01px;
        height: 228.88px;
    }
    .aboutSecondImage img{
        width: 181.25px;
        height: 177.62px;
    }
    .aboutThirdImage img{
        width: 200.02px;
        height: 174.01px;
    }
    .aboutSecondImage{
        position: absolute;
        right: 160px;
        top: 150px;
    }
    .aboutThirdImage{
        position: absolute;
        left: 140px;
        top: 115px;
    }
  }