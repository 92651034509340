@font-face{
  font-family: Hanimation Arabic;
  src: url(Hanimation_Arabic_Regular.otf);
}
*{
  margin: 0px;
  box-sizing: border-box;
  padding: 0px;
}
:root{
  --mainColor:rgb(255, 185, 0);
  --fontColor:#ffffff;
  --black:#000000;
  --fontBrimaryColor:#3D3D3D;
}
body {
  margin: 0;
  font-family:Hanimation Arabic, El Messiri,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Hanimation Arabic ,source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* ------------------- Navbar --------------------------*/
.navbar{
  z-index: 999;
}

.nav-link{
  display: inline-block !important;
}
:where(.css-dev-only-do-not-override-zjzpde).ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor::before, :where(.css-dev-only-do-not-override-zjzpde).ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor .ant-anchor-ink.ant-anchor-ink-visible{
  display: none;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor .ant-anchor-ink.ant-anchor-ink-visible{
    display: none;
}

.navbar-expand-lg .navbar-collapse{
  justify-content: center;
}
.ant-anchor{
  background-color: #000000;
  height: max-content;
}
:where(.css-dev-only-do-not-override-zjzpde).ant-anchor-wrapper .ant-anchor{
  display:flex;
  align-items:center;
  flex-wrap:wrap;
}
.ant-anchor-link-title{
  color: var(--fontColor) !important;
  text-decoration:none;
}
.navbar-toggler-icon{
  background-image: url('./menuIcon.png') !important;
}
@media (max-width: 700px){
  .navbar-toggler{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

.secondHeader{
  color: var(--mainColor);
  font-family: Hanimation Arabic;
  font-size: 36px;
  font-weight: 500;
  line-height: 74px;
  letter-spacing: 0em;
  border-bottom: 3px double;
  width: fit-content;
}
.thirdHeader{
  font-family: Hanimation Arabic;
  font-size: 24px;
  font-weight: 400;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: right;
  color: black;
}
/*---------------------------- last work ----------------------------------*/
.slick-prev:before, .slick-next:before{
  color: var(--mainColor) !important;
  background-color: blanchedalmond;
}
.slick-prev, .slick-next{
  top: -10% !important;
}
[dir='rtl'] .slick-prev{
  right:98.5% !important;
}
[dir='rtl'] .slick-next{
  left: 2% !important;
}
[dir='rtl'] .slick-prev:before {
  content: '←' !important;
}
[dir='rtl'] .slick-next:before {
  content: '→' !important;  
}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before{
  color: #828282 !important;
  opacity: 100% !important;
}
/*----------------------------- FAQ ----------------------------------*/
.accordion-button{
  font-family: Hanimation Arabic;
  font-size: 24px;
  font-weight: 400;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: right;
  color: var(--fontBrimaryColor);
}
.accordion-button:not(.collapsed) {
  color: var(--fontBrimaryColor);
  background-color: var(--fontColor);
  border: 1px solid #F4F4F4;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button::after{
  margin-right: auto;
  margin-left: 0px;
}

/*----------------------------------- contact ------------------------------- */
.Contact_contactUS__IqIjg input {
  transform: matrix(1, 0, 0, 1, 0, 0) !important;
}