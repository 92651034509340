
@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@500&display=swap');
.header{
    background-image: url('./heroSection.png') ;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    color: var(--fontColor);

    margin-bottom: 8rem;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-top: 112px;
}
.rightHeader{
    background: rgba(0, 0, 0, 0.3);
}
.rightHeader h1{
    font-family: Hanimation Arabic;
    font-size: 2.8125rem;
    font-weight: 500;
    line-height: 92.25px;
    letter-spacing: 0em;
    align-items: center;
    text-align: right;
    margin-top: 5rem;
    margin-right: 6.5rem;
}
.callNow{
    position: fixed;
    left: 5px;
    z-index: 999999999999;
}
.rightHeader button{
    background-color: var(--mainColor);
    color: var(--fontColor);
    font-family: Hanimation Arabic;
    font-size: 20px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    align-items: end;
    margin-bottom: 7rem;
    margin-right: 6.5rem;
}
.rightHeader button:hover{
    font-size: 21px;
    background-color: var(--mainColor);
}

.leftHeader{
    color: var(--fontColor);
    background-color: var(--mainColor);
    text-align: center;
    position: absolute;
    border-radius: 10px;
    bottom: -10%;
}
.leftHeaderItems{
    padding: 0.525rem 1.675rem;
}
.number{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 78px;
    color: #FFFFFF;
    text-align: center;
}
.leftHeader p{
    color: var(--fontColor);
    text-align: center;
    font-family: 'Hanimation Arabic';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 41px;
}
@media (max-width: 700px){
    .header{
        margin-top: 0rem;
    }
    .rightHeader h1{
        font-size: 2rem;
        line-height: 45px;
        text-align: center;
        margin: 8rem auto 12rem;
    }
    .leftHeader{
        bottom: -52px;      
    }
    .number{
        font-family: Poppins;
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
    }
    .leftHeader p{
        font-size: 16px;
        font-weight: 500;
        line-height: 33px;
    }
}