.teamcarousel{
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.reviewsPara{
    width: 451px;
    height: 123px;
    font-family: Hanimation Arabic;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 41px;
    text-align: right;
    color: #3D3D3D;
    mix-blend-mode: normal;
    text-align: right;
}
.quote{
    position: absolute;
    right: 86%;
    top: -12%;
    color: var(--mainColor);
}
.reviewerInfoWidth{
    width: 45%;
}
.reviewerInfo{
    border-top: 1px solid #F4F4F4;;
}
.reviewerName{
    font-family: Hanimation Arabic;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 0%;
    text-align: right;
    color: #2E3840;
    margin-top: 0.5rem;
}
.teamStart{
    color: var(--mainColor);
}
@media (max-width: 700px){
    .reviewsPara{
        width: 310px;
        height: 99px;
        font-size: 16px;
        line-height: 32.8px;
    }
    .reviewerInfoWidth{
        width: 75%;
    }
    .quote{
        position: absolute;
        right: 82%;
        top: -13%;
        color: var(--mainColor);
    }
}