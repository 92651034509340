@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@500&display=swap');
.contactUS{
    background: rgba(255, 185, 0, 0.10);
    margin-bottom: 5rem;
}
.contantPara{
    font-family: Hanimation Arabic;
    font-size: 30px;
    font-weight: 500;
    line-height: 61.5px;
    letter-spacing: 0em;
    text-align: right;
    color: #3D3D3D;
}
.contactUS input{
    font-family: Hanimation Arabic;
    color: #000000;
    width: 100%;
    height: 61px;
    font-size: 20px;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
}
.contactText{
    height: 231px !important;
    padding-top: 1px;
}
.contactUsBtn{
    font-family: Hanimation Arabic;
    color: #fff;
    justify-content: center;
    align-items: center;
    padding: 5px 70px;
    background-color: var(--mainColor);
    border-radius: 10px;
    border: 1px solid var(--mainColor);
    width: 100%;
    height: 61px;
    margin-top: 20px;
}

@media (max-width: 700px){
    .contactUSBody{
        flex-wrap: wrap-reverse;
    }
    .contactUS iframe{
        margin:1.5rem auto !important;
        width: 343px !important;
        height: 374px !important;
    }
    .contantPara{
        font-size: 18px;
        font-weight: 500;
        line-height: 40px;
    }
    .contactUS input{
        width: 343px;
        height: 61px;
        font-size: 16px;
    }
    .contactUsBtn{
        padding: 5px 70px;
        width: 343px;
        height: 61px;
        margin-top: 20px;
    }
  }
  