.teamcarousel{
    height: 328px;
    border-radius: 10px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

}
.temaMember{
    font-family: Hanimation Arabic;
    font-size: 24px;
    font-weight: 500;
    line-height: 49px;
    letter-spacing: 0em;
}
.jobTitle{
    font-family: Hanimation Arabic;
    font-size: 20px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    color: #828282;
    text-align: center;

}