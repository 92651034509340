.servicesIcon{
    color:var(--mainColor);
}
.servicesPara{
    font-family: Hanimation Arabic;
    font-size: 1.05rem;
    font-weight: 400;
    line-height: 44.2px;
    letter-spacing: 0em;
    text-align: right;
    color: var(--fontBrimaryColor);
}
@media (max-width: 700px){
    .servicesFirstSection, .servicesThirdSection{
        flex-wrap: wrap-reverse;
    }
    .servicesFirstSection img, .servicesSecondSection img, .servicesThirdSection img{
        width: 343px;
        height: 247px;
    }
    .servicesPara{
        font-size: 18px;
        line-height: 37px;
    }
  }